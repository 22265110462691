import { SaveButton, useNotify, useRedirect, useResourceContext } from "react-admin";
import AddIcon from "@mui/icons-material/Add";

const CreateSaveButton = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();

  return (
    <SaveButton  mutationOptions={{
      onSuccess: data => {
        notify(`Post "${data.title}" created!`);
        redirect("list",resource)
      }
    }
    } icon={<AddIcon />} type="submit" color="secondary" label="Create" />
  );
};

export default CreateSaveButton;

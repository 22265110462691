import decodeJwt from 'jwt-decode';


export default {
  login: async (token_url) => {
    const decode_token = decodeJwt(token_url);
    let url = decode_token.url;

    const request = new Request(url,{
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    });
    const res = await fetch(request);
    if(res.ok){
      const data = await res.json();
      const decode_token = decodeJwt(data.access_token);
      localStorage.setItem('auth', data.access_token);
      localStorage.setItem('permissions', decode_token.role);
      return Promise.resolve();
    }else{
      return Promise.reject();
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ ...rest }) => {

    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const permissions = localStorage.getItem('permissions');
    return permissions ? Promise.resolve(permissions) : Promise.reject();

  },
  getIdentity: () => Promise.resolve(),
};
import { Box, Divider, Typography } from "@mui/material";
import { BooleanInput, Create, SimpleForm, TextInput, DateTimeInput, required } from "react-admin";
import CreateToolbar from "../../components/CreateToolbar";
import { useState } from "react";

export const NotificationsCreate = () => {


  const [on, setOn] = useState(false);

  const cambiar = (e) => {
    setOn(!on)
  }

  return (
    <Create redirect="list">
      <SimpleForm toolbar={<CreateToolbar />} >
        <Typography id="typo" >Body</Typography>
        <Box sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "start"
        }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }} >
            <TextInput validate={required()} source="title" label="Title" sx={{ marginRight: "16px", width: "82%" }} />
            <Box sx={{ width: "100%" }}>
              <TextInput source="cta_name" label="CTA Name" sx={{ width: "40%" }} />
              <TextInput source="url" label="Link" sx={{ width: "40%", marginLeft: "2%" }} />
            </Box>
            <TextInput validate={required()} source="body" label="Body" multiline rows={5} sx={{ width: "82%" }} /></Box></Box>
        <Divider />
        <Typography id="typo" >Settings</Typography>
        <Box sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "start"
        }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }} >
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "start" }}>
              <DateTimeInput source="send_at" label="Send at" disabled={on} />
              <BooleanInput source="sendnow" onChange={cambiar} value={on} label="Send now" sx={{ marginLeft: "16px" }} />
            </Box>
            <Divider />
            <BooleanInput source="test" /></Box></Box>
      </SimpleForm>
    </Create>
  )
};

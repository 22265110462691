import { useResourceContext } from 'react-admin';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require('buffer').Buffer;

const useUploadAws = () => {
  const resource = useResourceContext();

  const configAWS = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    dirName: resource ? `originals/${resource}` : 'nodirName',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  };

  const uploadAWS = async (e) => {
    let file = e.target.files[0];
    console.log(e);
    let fileName = e.target.files[0].name;
    const reactS3Client = new S3(configAWS);
    const nameTrim = fileName?.replace(/[^a-zA-Z0-9]/g, '');
    const dataFile = await reactS3Client.uploadFile(file, nameTrim).then(data => {
      return ({...data, success: true});
    }).catch(e => {
      return ({ ...e, success: false});
    });
    return dataFile;
  };

  const uploadBase64Aws = async (base64Data, fileName, extension) => {

    const reactS3Client = new S3(configAWS);
    // const nameTrim = fileName?.replace(/[^a-zA-Z0-9]/g, '');
    const binaryData = Buffer.from(base64Data, 'base64');

    const blob = new Blob([binaryData], {type: `image/${extension}`});

    console.log(blob);

    const dataFile = await reactS3Client.uploadFile(blob, fileName, {contentType: `image/${extension}`}).then(data => {
      return { ...data, success: true };
    }).catch(e => {
      console.log('catch', e);
      return { ...e, success: false };
    });

    return dataFile;
  };

  return { uploadAWS, uploadBase64Aws};
};

export default useUploadAws;
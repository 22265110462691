import { Box, Checkbox, CircularProgress, Divider, Modal, Typography } from "@mui/material";
import { SimpleForm, TextInput, SelectInput, required, NumberInput, useRecordContext, BooleanInput, maxLength, useGetList, } from "react-admin";
import { useEffect, useState } from "react";
import AvailableDaysForm from "./indice";
import InputButtonAws from "../../components/ButtonAws";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CustomCheckbox from "../../components/inputs/CustomCheckbox";


export const ExperienceSimpleEdit = () => {

    const bookings = useGetList('bookings', { filter: {}, pagination: { perPage: 9999, page: 1 } });

    const [duration, setDuration] = useState(0);
    const record = useRecordContext();
    console.log('record', record.category)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };
    const cambiar = (e) => {
        setDuration(e.target.value);
    }
    useEffect(() => {
        setDuration(record.duration)
    }, [])

    const [open, setOpen] = useState((bookings?.data?.find(a => (a.experience_id === record.id))) ? true : false);

    const handleClose = () => {
        setOpen(false)
    };
    while (bookings.status === 'loading' || !record) {
        return <Box sx={{ width: "100%", height: "600px", display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box>
    }

    return (
        <SimpleForm   >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Warning
                        </Typography>


                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            This experience has bookings
                        </Typography>
                    </Box>
                    <WarningAmberIcon sx={{ fontSize: 60 }} />
                </Box>
            </Modal>
            <Typography id="typo" >Body</Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "80%",
                    marginLeft: "40px",
                }}
            >
                <InputButtonAws />
                <Box width="100%" sx={{ display: "flex" }}>

                    <TextInput
                        sx={{ marginRight: "16px" }}
                        fullWidth
                        source="title"
                        validate={[required()]}
                    />
                    <SelectInput
                        sx={{ marginLeft: "0px" }}
                        source="category"
                        fullWidth
                        validate={[required()]}
                        defaultValue={"Excursions"}
                        label="Category"
                        choices={[
                            { id: "Excursions", name: "Excursions" },
                            { id: "Spa therapy", name: "Spa therapy" },
                            { id: "Transports", name: "Transports" },
                            { id: "Yoga Lessons", name: "Yoga Lessons" },
                        ]}
                    />
                </Box>
                <Box width="100%" sx={{ display: "flex" }}>
                    <NumberInput
                        sx={{ marginRight: "8px" }}
                        fullWidth
                        source="price"
                        validate={[required()]}
                    />
                    <TextInput
                        sx={{ marginLeft: "8px" }}
                        fullWidth
                        source="cta"
                        validate={[required(), maxLength(10, "Max 10 characters")]}
                    />
                </Box>{" "}
                <Box width="100%" sx={{ display: "flex", marginBottom: "20px" }}>
                    <NumberInput
                        source="duration"
                        label="Duration (mins)"
                        sx={{ width: "49.4%" }}
                        helperText={false}
                        onChange={cambiar}
                        validate={[required()]}
                    />                    
                    <BooleanInput source="hide"
                        sx={{
                            display: 'flex',
                            alignItems: "start",
                            justifyContent: "start",
                            height: "16px",
                            marginLeft: "40px"
                        }} />
                    
                </Box>
                <Box sx={{ display: "flex", width: "100%" }}>
                    <TextInput
                        sx={{ width: "100%" }}
                        source="description"
                        multiline
                        rows={3}
                        validate={[required(), maxLength(300, "Maximo caracteres 300")]}
                    />

                </Box>
                {/* <Box sx={{ display: "flex", width: "100%", alignItems: 'center' }}>
                    <Checkbox source="featured" />
                    <Typography sx={{ }}>Featured</Typography>
                </Box> */}
                <CustomCheckbox 
                    label="Featured"
                    source="featured"
                />


            </Box>

            <Divider sx={{ width: "70vw", bgcolor: "black", marginTop: "30px", marginBottom: "15px" }} />
            <Typography id="typo">Settings</Typography>
            <Box sx={{ marginLeft: "40px", width: "80%" }}>
                { record.category != 'Transports' ?
                <Box width="100%" sx={{ display: "flex", height: "80px" }}>
                    <SelectInput
                        source="deadline"
                        fullWidth
                        defaultValue={15}
                        label="Deadline"
                        validate={[required()]}
                        sx={{ marginRight: "16px" }}
                        choices={[
                            { id: 15, name: "15 minutes before the experience" },
                            { id: 30, name: "30 minutes before the experience" },
                            { id: 60, name: "1 hour before the experience" },
                            { id: 120, name: "2 hours before the experience" },
                            { id: 180, name: "3 hours before the experience" },
                            { id: 240, name: "4 hours before the experience" },
                            { id: 1440, name: "1 day before the experience" },
                            { id: 10080, name: "1 week before the experience" },
                        ]}
                    />
                </Box>
                : null }
                <Box>
                    {record.category != 'Transports' ? <AvailableDaysForm duration={duration} /> 
                    :
                    null
                    }
                </Box>
            </Box>
        </SimpleForm>

    );
};

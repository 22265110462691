import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react'
import { useInput } from 'react-admin';

const CustomCheckbox = ({ source, ...rest }) => {
    const { field } = useInput({ source });

    return (
        <Box sx={{ display: "flex", width: "100%", alignItems: 'center' }}>
            <FormGroup>
            <FormControlLabel 
                style={{
                marginLeft: '0px'
                }}
                control={ <Checkbox
                onClick={(e) => field?.onChange(e.target.checked)}
                checked={field?.value}
                />} {...rest} />
            </FormGroup>
        </Box>
    )
}

export default CustomCheckbox
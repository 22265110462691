export const getQueryParams = ({ pagination, sort, filter = {} }) => {
  const filterString = JSON.stringify(filter);

  const query = {
    limit: `limit=${pagination.perPage}`,
    offset: `offset=${(pagination.page - 1) * pagination.perPage}`,
    sort: `sort_by=${sort.field}&ascending=${sort.order == "DESC"}`,
    filter: filterString,
  };

  return `?${query.limit}&${query.offset}&${query.sort}&filter=${query.filter}`;
};

export const createIdsArray = (data) => {
  return data.map((d, index) => ({ id: index, text: d }));
};

import { Toolbar } from "react-admin";
import CreateSaveButton from "./CreateSaveButton";

import CancelButton from "./CancelButton";

const CreateToolbar = () => {

  return (
    <Toolbar
      sx={{
        backgroundcolor: "blue",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <CreateSaveButton/>
      <CancelButton />
    </Toolbar>
  );
};

export default CreateToolbar;

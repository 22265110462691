import { Layout } from 'react-admin';
import { MyMenu } from './MyMenu';


export const MyLayout = props => <Layout {...props} sx={{
    "& #main-content":
        { backgroundColor: "#f0f0f0", paddingLeft: "20px", paddingRight: "20px" }
    ,
    "& .RaDatagrid-root ":
        { minHeight: "85vh" }
}} menu={MyMenu} />;
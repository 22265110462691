import { Box, Button, Divider, Typography } from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const CancelButton = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNavigate = () => {
    navigate(-1)
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          backgroundColor: "#9c1c1c",
          color: "white",
          "&:hover": {
            backgroundColor: "#7a0800",
          },
        }}
      >
        Cancel
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: "130px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Box>
            <Typography fontSize={30}>Cancel</Typography>
            <Divider />
            <Typography marginTop={2}>
              Are you sure? All fields will be deleted
            </Typography>
           
            <Box marginTop={2} sx={{ display: "flex", justifyContent: "end" }}>
              {" "}
              <Button
                onClick={handleClose}
                sx={{

                  color: "black",
                  marginRight: "10px",
                  "&:hover": {

                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleNavigate}
                sx={{
                  backgroundColor: "#c71c10",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#a8190f",
                  },
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CancelButton;

import {  Datagrid, TextField, UrlField, DateField } from "react-admin";

export const NotificationsListRecord = () => {

    const postRowSx = (record, index) => ({
        backgroundColor: record.status == "sent" ? '#ededed' : 'white',
        "td": { color: record.status == "sent" ? '#6b6b6b' : '#1f1f1f' },

    });

    return (
        <Datagrid bulkActionButtons={false} rowSx={postRowSx} hover={false}   >
            <TextField source="title" />
            <TextField source="body" />
            <UrlField source="url" />
            <DateField options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }} source="send_at" />
            <TextField source="status" />
        </Datagrid>)
};

import { Box, Divider, Typography } from "@mui/material";
import { SimpleForm, TextInput, SelectInput, required, NumberInput, BooleanInput, maxLength, } from "react-admin";
import CreateToolbar from "../../components/CreateToolbar";
import { useState } from "react";
import AvailableDaysForm from "./indice";
import InputButtonAws from "../../components/ButtonAws"

export const ExperienceSimpleCreate = () => {

    const [duration, setDuration] = useState(0);

    const cambiar = (e) => {
        setDuration(e.target.value);
    }
    
    return (
        <SimpleForm toolbar={<CreateToolbar />}   >
            <Typography id="typo" >Body</Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "80%",
                    marginLeft: "40px",
                }}
            >
                <InputButtonAws/>
                <Box width="100%" sx={{ display: "flex" }}>

                    <TextInput
                        sx={{ marginRight: "16px" }}
                        fullWidth
                        source="title"
                        validate={[required()]}
                    />
                    <SelectInput
                        sx={{ marginLeft: "0px" }}
                        source="category"
                        fullWidth
                        validate={[required()]}
                        defaultValue={"Excursions"}
                        label="Category"
                        choices={[
                            { id: "Excursions", name: "Excursions" },
                            { id: "Spa therapy", name: "Spa therapy" },
                            { id: "Transports", name: "Transports" },
                            { id: "Yoga Lessons", name: "Yoga Lessons" },
                        ]}
                    />
                </Box>
                <Box width="100%" sx={{ display: "flex" }}>
                    <NumberInput
                        sx={{ marginRight: "8px" }}
                        fullWidth
                        source="price"
                        validate={[required()]}
                    />
                    <TextInput
                        sx={{ marginLeft: "8px" }}
                        fullWidth
                        source="cta"
                        validate={[required(),maxLength(10, "Max 10 characters")]}
                    />
                </Box>{" "}
                <Box width="100%" sx={{ display: "flex", marginBottom: "20px" }}>
                    <NumberInput
                        source="duration"
                        label="Duration (mins)"
                        sx={{ width: "49.4%" }}
                        helperText={false}
                        onChange={cambiar}
                        validate={[required()]}
                    />
                    <BooleanInput source="hide"
                        
                        sx={{
                            display: 'flex',
                            alignItems: "start",
                            justifyContent: "start",
                            height: "16px",
                            marginLeft: "40px"
                        }} />
                </Box>
                <Box sx={{ display: "flex", width: "100%" }}>
                    <TextInput
                        sx={{ width: "100%" }}
                        source="description"
                        multiline
                        rows={3}
                        validate={[required(),maxLength(300, "Max 300 characters")]}
                    />

                </Box>
            </Box>
            <Divider sx={{ width: "70vw", bgcolor: "black" }} />
            <Typography id="typo">Settings</Typography>
            <Box sx={{ marginLeft: "40px", width: "80%" }}>
                <Box width="100%" sx={{ display: "flex", height: "80px" }}>
                    <SelectInput
                        source="deadline"
                        fullWidth
                        defaultValue={15}
                        label="Deadline"
                        validate={[required()]}
                        sx={{ marginRight: "16px" }}
                        choices={[
                            { id: 15, name: "15 minutes before the experience" },
                            { id: 30, name: "30 minutes before the experience" },
                            { id: 60, name: "1 hour before the experience" },
                            { id: 120, name: "2 hours before the experience" },
                            { id: 180, name: "3 hours before the experience" },
                            { id: 240, name: "4 hours before the experience" },
                            { id: 1440, name: "1 day before the experience" },
                            { id: 10080, name: "1 week before the experience" },
                        ]}
                    />
                </Box>
                <Box>
                    <AvailableDaysForm duration={duration} />
                </Box>
            </Box>
        </SimpleForm>

    );
};


import { Edit, } from "react-admin";
import { ExperienceSimpleEdit } from "./ExperienceSimpleEdit";

export const ExperiencesEdit = () => {

  return (
    <Edit redirect="list">
      <ExperienceSimpleEdit />
    </Edit>
  );
};

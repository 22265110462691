import { FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useInput } from 'react-admin';

const InputTime = ({ source, validate, valor, setear, setValor, duration }) => {
    const { id, field, fieldState } = useInput({ source, validate });

    function sumarMinutosAHora(hora, minutos) {
        const [horas, minutosActuales] = hora.split(':').map(Number);
        var newDate = dayjs().hour(horas).minute(minutosActuales)
        newDate.add('minute', minutos)
        return newDate.add(minutos, 'minute').format('HH:mm');
    }

    const cambiar = (e) => {
        setValor(sumarMinutosAHora(e.target.value, duration))
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                fontWeight: "400",
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "0.8rem",
                marginTop: "3px"
            }}>

            <label htmlFor={id}
                onChange={cambiar}
                style={{
                    padding: "3px 12px 4px 12px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "1px solid #8e8e8e",
                    color: fieldState.invalid && 'red',
                    borderRadius: "5px 5px 0px 0px",
                    borderColor: fieldState.invalid && 'red',
                    display: "flex",
                    flexDirection: "column"
                }}>From

                <input id={id}
                    {...field}
                    type='time'
                    style={{
                        height: "22px",
                        outline: "none",
                        border: "none",
                        backgroundColor: "#f5f5f5",
                        fontFamily: "sans-serif",
                        fontSize: "15px"
                    }}

                />

            </label> {fieldState.invalid && <FormHelperText
                sx={{ color: 'red' }}>The Time is required</FormHelperText>}</div>
    );

};

export default InputTime;
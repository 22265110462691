import { Create, } from "react-admin";
import { ExperienceSimpleCreate } from "./ExperiencesSimpleCreate";

export const ExperiencesCreate = () => {

  return (
    <Create redirect="list">
      <ExperienceSimpleCreate />
    </Create>
  );
};

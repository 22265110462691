import { apiUrl } from '../dataProvider/dataProvider';

console.log('apiurl', apiUrl)

const useEmailAuth = () => {
  const emailAuth = async (email) => {
    const request = new Request(
      `${apiUrl}/login?email=${encodeURIComponent(
        email
      )}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );
    const res = await fetch(request);
    const data = await res.json();

    return { data, res };
  };

  return emailAuth;
};

export default useEmailAuth;

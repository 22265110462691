import { ArrayInput, AutocompleteArrayInput, NumberInput, SimpleFormIterator, SimpleFormIteratorItemContext, required } from "react-admin";
import { TimeDate } from "./CreateTime";
import { useState } from "react";

import InputTime from "./InputTime";
import { useFormContext } from "react-hook-form";

const AvailableDaysForm = ({ duration }) => {

    var indexx = 0;
    const [stock, setStock] = useState(1);
    const cambiarStock = (e) => {
        setStock(e.target.value);
    }
    const [valor, setValor] = useState("12:00")

    const setear = (e) => {
        setValor(e.target.value)
    }

    const [dias, setDias] = useState([
        { id: 'monday', name: "Monday" },
        { id: 'tuesday', name: "Tuesday" },
        { id: 'wednesday', name: "Wednesday" },
        { id: 'thursday', name: "Thursday" },
        { id: 'friday', name: "Friday" },
        { id: 'saturday', name: "Saturday" },
        { id: 'sunday', name: "Sunday" }])


    return (
        <ArrayInput
            source="available_days"
            defaultValue={[{}]} >
            <SimpleFormIterator
                getItemLabel={index => { indexx = index }}
                disableReordering >
                <AutocompleteArrayInput
                    source="day"
                    label="Day"
                    defaultValue={[dias[0].id]}
                    required
                    choices={dias} />
                <ArrayInput

                    source="available_hours"
                    defaultValue={[{}]} >
                    <SimpleFormIterator
                        disableReordering

                        inline
                        sx={{
                            "& .RaSimpleFormIterator-form": {
                                display: "flex", alignItems: "center"
                            }
                        }}  >
                        <NumberInput
                            source="max_capacity"
                            onChange={cambiarStock}
                            defaultValue={stock}
                            label="Stock"
                            helperText={false}
                            validate={[required()]}
                        />
                        <InputTime

                            defaultValue={valor}
                            duration={duration}
                            source={"time"}
                            label={"Time"}
                            validate={[required()]}
                            setValor={setValor}
                            valor={valor}
                            setear={setear} />
                        <SimpleFormIteratorItemContext.Consumer>{({ index }) => {
                            return (<>
                                <TimeDate
                                    duration={duration}
                                    index={index}
                                    index2={indexx} />
                            </>)
                        }}
                        </SimpleFormIteratorItemContext.Consumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>

    );
};

export default AvailableDaysForm;
import { TextField } from "@mui/material"
import dayjs from "dayjs"
import { useState } from "react"
import { required } from "react-admin"
import { useFormContext } from "react-hook-form"

export const TimeDate = ({ duration, index, index2 }) => {

    const { getValues } = useFormContext()
    const value = getValues();

    function sumarMinutosAHora(hora, minutos) {
      
        const [horas, minutosActuales] = hora.split(':').map(Number);
        var newDate = dayjs().hour(horas).minute(minutosActuales)
        newDate.add('minute', minutos)
    
        return newDate.add(minutos, 'minute').format('HH:mm');
    }


    return (
        <>
            {
                value?.available_days[index2]?.available_hours[index]?.time ?
                    <TextField
                        disabled
                        sx={{ maxWidth: "100px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}
                        label="To"
                        value={sumarMinutosAHora(value?.available_days[index2]?.available_hours[index]?.time, duration)}
                    /> : null
            }
        </>
    )
}
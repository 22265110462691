import { Box, Typography } from "@mui/material";
import { Create, SimpleForm, TextInput,  required, SelectInput } from "react-admin";
import CreateToolbar from "../../components/CreateToolbar";

export const AdminCreate = () => (
    <Create >
        <SimpleForm toolbar={<CreateToolbar />} >
            <Typography id="typo">Information</Typography>
            <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "start"
            }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }} >
                    <TextInput source="name" label="Name" fullWidth validate={[required()]} />
                    <TextInput source="email" label="Email" fullWidth validate={[required()]} />
                    <SelectInput
                        sx={{ marginLeft: "0px" }}
                        source="role"
                        fullWidth
                        validate={[required()]}
                        defaultValue={"Excursions"}
                        label="Role"
                        choices={[
                            { id: "superadmin", name: "Super Admin" },
                            { id: "admin", name: "Admin" },
                            { id: "staff", name: "Staff" },
                        ]}
                    />
                </Box></Box>
        </SimpleForm>
    </Create>
);

import React, { useState } from 'react';
import { Alert, Avatar, Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { useLogin } from 'react-admin';
import useEmailAuth from '../../hooks/useEmailAuth';

const invalidMessage = {
  data: { msg: 'This link was already used before, please try another one.', status: 'error' },
  res: { ok: true }
};

const statusMessage = {
  201: {
    data: { msg: 'A verification email has been sent to your email address.', status: 'success' },
    res: { ok: true }
  },
  404: {
    data: { msg: 'Email address not found.', status: 'error' },
    res: { ok: true }
  }
};


const LoginPageCustom = (props) => {
  const [toggleButton, setToggleButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const emailAuth = useEmailAuth();
  const isAuthLink = window.location.href.includes('token_url');
  const login = useLogin();

  const authWithToken = () => {
    const hash = window.location.hash;
    const tokenUrl = hash?.split('?')[1]?.split('=')[1];
    console.log('aca', tokenUrl)
    if (tokenUrl == null) {
      return;
    }
    login(tokenUrl).then((res) => {
      setLoading(false);
    }).catch(e => {
      setShow(invalidMessage);
      setLoading(false);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setToggleButton(true);
    const data = new FormData(event.currentTarget);
    const r = await emailAuth(data.get('email'));

    setShow(statusMessage[r.res?.status]);
    setToggleButton(false);
  };


  React.useEffect(() => {

    if (isAuthLink) {
      setLoading(true);
      authWithToken();
    }
    // else {
    //   localStorage.getItem('auth') && redirect('/socios');
    // }
  }, [isAuthLink]);


  return (
    <Box>
      <Grid container component="main" sx={{ height: '100vh' }} display={loading && 'none'}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* {!noShow &&
              <Box sx={{ position: 'absolute', width: '100vw', height: '100vh', backgroundColor: '#fff' }}>
              </Box>
            } */}
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              {/* <LockOutlinedIcon /> */}
            </Avatar>
            <Typography component="h1" variant="h5">
              Selva Armonia Backoffice
            </Typography>
            <Box component="form" onSubmit={handleSubmit} style={{ marginTop: '10px', width: '300px' }}>
              <TextField
                disabled={toggleButton}
                margin="normal"
                variant='outlined'
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {
                show ?
                  <Alert variant="filled" severity={show.res.ok ? show.data.status : 'error'}>
                    {show.res.ok ? show.data.msg : 'Email address not found'}
                  </Alert>
                  : null
              }
              <Button
                disabled={toggleButton}
                fullWidth
                variant="contained"
                type='submit'
                color='secondary'
                style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}
              >
                {toggleButton ?
                  <CircularProgress size={24} /> :
                  <Typography style={{ textAlign: 'center', width: '100%' }} variant='subtitle2'>Sign In</Typography>
                }
              </Button>
              <Grid container justifyContent='flex-end'>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* )} */}
    </Box>
  );
};

export default LoginPageCustom;
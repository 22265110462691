import { Box, Modal, Button, Typography, Grid, CircularProgress } from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import { useGetList, useNotify } from 'react-admin';
import { AiFillSave } from 'react-icons/ai';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { theme } from '../../theme';
import { apiUrl } from '../../dataProvider/dataProvider';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { Button } from 'react-admin';

const styleBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#fff',
  boxShadow: 24,
  width: '100%',
  height: '80%',
  maxWidth: '1000px',
  maxHeight: '700px',
  minHeight: '500px',
  padding: '24px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  justifyContent: 'center',
};

export const RankingModal = () => {
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // No borrar esto
  const [open, setOpen] = useState(false);
  const [rankedArr, setRankedArr] = useState([]);
  const gridRef = useRef();
  const notify = useNotify();
  const { data: rankings } = useGetList('experiences/rank', {offset: 1, pagination: {page: 1, perPage: 9999}});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setRankedArr(rankings?.map((item , i)=>{return {id: item.id, name: item.title, rank: i};}))
  },[rankings])

  // useEffect(() => {
  //   rankings?.map((item, i) => { console.log('asd', item)})
  // }, [rankings])

  const handleArrPositions = (selfId, hoverId) => {
    let newArr = rankedArr;
    let dragedItem = newArr.find(item => item.id === selfId);
    let droppedItem = newArr.find(item => item.id === hoverId);
    const draggedIndex = newArr.indexOf(dragedItem);
    const droppedIndex = newArr.indexOf(droppedItem);
    
    if (draggedIndex > droppedIndex) {
      newArr.splice(draggedIndex, 1);
      newArr.splice(droppedIndex, 0, dragedItem);
    } else if (draggedIndex < droppedIndex) {
      newArr.splice(droppedIndex, 1);
      newArr.splice(draggedIndex, 0, droppedItem);
    }
    return setRankedArr(newArr);
  };


  function newPosition(x, y, item) {
    let hoveredItem = document.elementFromPoint(x, y);
    return handleArrPositions(item, hoveredItem?.id);
  }

  const [{  getSourceClientOffset, canDrop, isOver, dropped, draggedItem }, drop] = useDrop({
    accept: 'ITEM',
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const parentRef = gridRef.current.getBoundingClientRect();
      const x = clientOffset.x - parentRef.left;
      const y = clientOffset.y - parentRef.top;
      if(monitor.isOver()) newPosition(x,y, item.id);
    },
    hover: (item, monitor) =>  {
      const clientOffset = monitor.getClientOffset();
      const x = clientOffset.x;
      const y = clientOffset.y; 
      if(item) newPosition(x,y, item.id);
    },
    collect: (monitor) => ({
      getSourceClientOffset: monitor.getSourceClientOffset(),
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      dropped: monitor.getDropResult(),
      draggedItem: monitor.getItem(),

    }),
  });

  const handlePutVenues = async () => {
    setLoading(true);
    const updatedRanks = rankedArr.map(item => item.id);
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': 'content-range',
      'Authorization': 'Bearer ' + localStorage.getItem('auth'), 
      'x-api-key': process.env.REACT_APP_API_KEY, 
    };
    try {
      const response = await axios({url: `${apiUrl}/experiences/rank`, method: 'PUT', headers: headers, data: updatedRanks });
      const data = await response.data;
      data && notify('Experience rankings have been succesfuly updated', {type: 'success'});
      setLoading(false);
      setOpen(false);
      return;
    } catch (error) {
      notify('There has been an error, please try again later.', {type: 'error'});
      setLoading(false);
      return;
    }
  };

  const handleCancel = () => {
    setRankedArr(rankings?.map((item , i)=>{return {id: item.id, name: item.title, rank: i};}));
    setOpen(false);
  };

  return (
    <>
        <Button onClick={handleOpen} variant="outlined" color="secondary" sx={{ padding:'3px 9px'}}>Experience Rank</Button>
        <Modal
          open={open}
          onClose={handleClose}
          data-testid="modal-venues-ranking"
          ref={gridRef} 
        >
          <Box ref={drop} style={styleBox}>
            <Box sx={{
              border: '1px solid #bebebe',
              display: 'grid',
              gridTemplateColumns: '1fr ',
              width: '100%',
              maxWidth: '100%',
              
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '8px 8px 0 0'
            }}>
              <Box data-testid="modal-header" sx={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%', margin: '10px', gap: '40px',flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                <Typography sx={{color: '#606060', fontSize: '.9em', fontWeight: '600',  justifySelf: 'start'}}>Ranking</Typography>
                <Typography sx={{color: '#606060', fontSize: '.9em', fontWeight: '600',  justifySelf: 'start'}}>Initial Ranking</Typography>
                <Typography sx={{color: '#606060', fontSize: '.9em', fontWeight: '600',  justifySelf: 'start'}}>Experience name</Typography>
              </Box>
            </Box>
            {
              rankedArr && (
                <>
                  <Grid sx={{ borderBottom: '1px solid #bebebe', display: 'flex', alignItems: 'center', justify: 'center', maxHeight: '100%', height: '100%', overflow: 'hidden', overflowY: 'scroll'}} container spacing={0} >
                    {
                      rankedArr?.map((item, i) => <Row setIsDragging={setIsDragging} gridRef={gridRef} index={i} row={item} />)
                    }
                  </Grid>
                </>
              )
            }
            <Box sx={{display: 'flex', justifyContent: 'space-between',marginTop: '20px'}}>
              <Button onClick={() => handleCancel()} variant="outlined" sx={{backgroundColor: '#ff6060', color: '#fff', justifySelf: 'flex-start', '&:hover': {color: '#ff6060', borderColor: '#ff6060'}}}>Cancel</Button>
              <Button startIcon={loading ? <CircularProgress size='18px' variant="indeterminate" /> : <AiFillSave size="18px" />} onClick={() => handlePutVenues()} variant="outlined" sx={{backgroundColor: '#658369', color: '#fff', justifySelf: 'flex-end', '&:hover': {color: theme.palette.primary.main}}}>Save Experience rankings</Button>
            </Box>
          </Box>
        </Modal>
    </>
  )
}

const Row = ({setIsDragging, gridRef, row, index}) => {
 
  const { item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  const [{  draggedItem, getSourceClientOffset, isDragging, }, drag] = useDrag({
    type: 'ITEM',
    previewOptions: {
    
    },
    item: { 
      ...row,
      type: 'ITEM',
      id: row.id,
    },
    collect: (monitor) => ({
    //   draggedItem: monitor.item(),
      getSourceClientOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      draggedItem: monitor.getItem()?.id,
      initialClientOffset: monitor.getInitialClientOffset(),
    }),
    options: {
      snapToGrid: 5, // specify the grid size for snapping
      snapToGridOffset: [0, 0], // specify the offset if needed
      //   snapToGridCallback: snapToGrid // specify the snapping function
    }
  });

  useEffect(() =>  {
    // console.log(currentOffset);
    if(item) return setIsDragging({position: currentOffset, item: item?.id});
    setIsDragging({position: currentOffset, item: item?.id});
    return() => {return setIsDragging({position: null, item: null});};
  }, [currentOffset]);

  const styledItem = {
    // padding: '2%',
    // borderRadius: '5px',
    border: '1px solid #bebebe',
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    maxWidth: '80vw',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: isDragging ? 0.5 : 1, cursor: 'grab',
    '&:hover': {
      borderColor: 'red',
      border: `2px solid${theme.palette.primary.main}`,
      backgroundColor: '#e4ebe5',
    }
  };

  const styledDragged = {
    // padding: '2%',
    //borderRadius: '12px',
    border: `2px solid${theme.palette.primary.main}`,
    display: 'grid',
    gridTemplateColumns: '1fr',
    backgroundColor: '#f2d5b7',
    width: '100%',
    maxWidth: '80vw',
    flexDirection: 'row',
    justifyContent: 'space-around',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
    alignItems: 'center',
    opacity: isDragging ? 0.2 : 1, cursor: 'move',
    '&:active': {
      backgroundColor: '#fff'

    }
  };

  const styledName = {
    justifySelf: 'start',
    textAlign: 'start',
    alignSelf: 'start',
    fontSize: '.9em',
    color: theme.palette.primary,
    textTransform: 'uppercase',
    '&:nth-of-type(1)': {
      justifySelf: 'start',
    },
    '&:nth-of-type(2)': {
      justifySelf: 'center',
    },
    '&:nth-of-type(3)': {
      justifySelf: 'end',
    },
  };

  return(
    <Box id={row.id} ref={drag} sx={draggedItem === row?.id ? styledDragged : styledItem}>
      <Box  id={row.id} sx={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%', margin: '10px', gap: '40px',flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
        <Box sx={{display: 'flex', gap: '9px', alignContent: 'center' }}>
        <MoreVertIcon sx={{ color: theme.palette.primary.main }}/>
        <Typography style={styledName} styleid={row.id}>{index}</Typography>
        </Box>
        <Typography style={styledName} id={row.id}>{row.rank}</Typography>
        <Typography style={styledName} id={row.id}>{row.name}</Typography>
      </Box>
    </Box>
  );
};

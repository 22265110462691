import { Show, SimpleShowLayout, TextField, Toolbar, SaveButton, DeleteButton } from "react-admin";
import ShowToolbar from "../../components/ShowToolbar";

export const BookingShow = () => (
    <Show actions={<ShowToolbar />}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="time_selected" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email" />
            <TextField source="phone" />
            <TextField source="num_participants" label="Extra participants" />
        </SimpleShowLayout>
    </Show>
);


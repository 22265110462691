import { Admin, Resource } from "react-admin";
import { dataProvider } from "./dataProvider/dataProvider";
import { NotificationsCreate } from "./resources/notifications/NotificationsCreate";
import { QueryClient } from "react-query";
import authProvider from "./authProvider";
import LoginPageCustom from "./resources/login/LoginCustom";
import { ExperienceList } from "./resources/experiences/ExperiencesList";
import { ExperiencesCreate } from "./resources/experiences/ExperiencesCreate";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { NotificationsList } from "./resources/notifications/NotificationsList";
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MyLayout } from "./components/MyLayout";
import { ExperiencesEdit } from "./resources/experiences/ExperiencesEdit";
import { Bookinglist } from "./resources/booking/BookingList";
import { theme } from "./theme";
import QuizIcon from '@mui/icons-material/Quiz';
import { BookingShow } from "./resources/booking/BookingShow";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { AdminList } from "./resources/admins/AdminList";
import { AdminCreate } from "./resources/admins/AdminCreate";
import { FAQsEdit } from "./resources/faqs/FAQsEdit";
import { FAQsCreate } from "./resources/faqs/FAQsCreate";
import { FAQsList } from "./resources/faqs/FAQsList";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const App = () => (

  <Admin
    queryClient={queryClient}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPageCustom}
    theme={theme}

    layout={MyLayout}
  >
    <Resource
      name="notifications"
      list={NotificationsList}
      create={NotificationsCreate}
      options={{ label: "Notifications" }}
      icon={NotificationsIcon}
    />
    <Resource
      name="admins"
      list={AdminList}
      create={AdminCreate}
      options={{ label: "Admin" }}
      icon={SupervisorAccountIcon}
    />
    <Resource
      name="bookings"
      list={Bookinglist}
      edit={BookingShow}
      options={{ label: "Booking List" }}
      icon={EventNoteIcon}
    />

    <Resource
      name="experiences"
      options={{ label: "Experiences" }}
      list={ExperienceList}
      create={ExperiencesCreate}
      edit={ExperiencesEdit}
      icon={SelfImprovementIcon}
    />
        {/* <Resource
      name="faqs"
      options={{ label: "Faqs" }}
      list={FAQsList}
      create={FAQsCreate}
      icon={QuizIcon}
    /> */}


    {/* <Resource
      name="faqs"
      options={{ label: "Faqs" }}
      list={FAQsList}
      create={FAQsCreate}
      edit={FAQsEdit}
      icon={QuizIcon}
    /> */}

  </Admin>

);

import { List, Datagrid, TextField, NumberField, ChipField, TopToolbar, CreateButton, ExportButton} from "react-admin";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RankingModal } from "../../components/experiences/RankingModal";
import PostPagination from "../../components/PostPagination";

const ListActionsCustom = () => (
  <TopToolbar>
      <CreateButton/>
      <ExportButton/>
      <RankingModal />
  </TopToolbar>
);

// const paramsList = {
//   listParams: {
//     hasCreate: true,
//     actions: <ListActions><RankingModal /></ListActions>
//   },
//   datagridParams:{
//     bulkActionsButtons: false,
//   }
// }

export const ExperienceList = () =>{ 
  
  const postRowSx = (record, index) => ({
    backgroundColor: record.hide  ? '#ededed' : 'white',
    "td": { color: record.hide ? '#6b6b6b' : '#1f1f1f' },

});
  
  return(
  <DndProvider backend={HTML5Backend} key={1}>
  <List pagination={<PostPagination />}  actions={<ListActionsCustom/>}>
    <Datagrid bulkActionButtons={false} rowSx={postRowSx}  rowClick="edit"   >
      <TextField source="title" />
      <ChipField textAlign="left" source="category" />
      <TextField source="description" />
      <NumberField textAlign="left" source="duration" options={{ style: 'unit', unit: 'minute' }} />
      <NumberField textAlign="left" options={{
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }} source="price" />
      <TextField source="featured" />
    </Datagrid>
  </List>
  </DndProvider>
)};

import { fetchUtils } from "react-admin";
import { getQueryParams } from "./api";

// let test = process.env.REACT_APP_TEST
// let isTest = test === 'true'
// export const apiUrl = isTest ? process.env.REACT_APP_JSON_SERVER_URL_TEST : process.env.REACT_APP_JSON_SERVER_URL;
export const apiUrl = process.env.REACT_APP_JSON_SERVER_URL

// console.log('test mode:', test, apiUrl)
console.log('-test mode:', apiUrl)

const httpClient = async (url, options = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Access-Control-Allow-Origin", "*");
  options.headers.set("Access-Control-Expose-Headers", "content-range");
  options.headers.set(
    "Authorization",
    "Bearer " + localStorage.getItem("auth")
  );
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = {
  getList: (resource, params) => {
    const query = {
      pagination: params.pagination,
      sort: params.sort,
      filter: params.filter,
    };
    const queryParams = getQueryParams(query);
    const url =  `${apiUrl}/${resource}/${queryParams}`;

    return httpClient(url).then(({ headers, json }) => {
      let data = json?.results;

      return {
        data: data,
        total: json?.paging?.total,
      };
    });
  },
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })), // get a single record by id
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${JSON.stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      let data = json.results;
      return {
        data: data,
      };
    });
  }, // get a list of records based on an array of ids
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${JSON.stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.results,
      total: json.paging.total,
    }));
  }, // get the records referenced to another record, e.g. comments for a post

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })), // update a record based on a patch
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${JSON.stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  }, // update a list of records based on an array of ids and a common patch
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${JSON.stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

import { defaultTheme } from "react-admin";

export const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#84a1a2",
    },
    secondary: {
      main: "#658369",
      // main: '#00d2a6'
    },
  },
};

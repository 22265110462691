import { List} from "react-admin";
import { NotificationsListRecord } from "./NotificationListRecord";
import PostPagination from "../../components/PostPagination";

export const NotificationsList = () => (


  <List pagination={<PostPagination />}>
    <NotificationsListRecord />
  </List>
);

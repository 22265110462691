import React, { useState } from 'react';
import { Box, Button, FormHelperText, FormLabel, Typography } from '@mui/material';
import { Title, useGetRecordId, useInput, useResourceContext } from 'react-admin';
import useUploadAws from './uploadAws';


const InputButtonAws = ({helperText, actionText, noImage, validate, label, source = 'picture.original' ,sx}) => {
  const { id, field, fieldState } = useInput({ source, validate });
  const imageFieldSource = field?.value?.length > 0 ? { key: field.name, location: field.value } : null;
  const [image, setImage] = useState(imageFieldSource);
  const { uploadAWS } = useUploadAws();

  const handleClick = async (e) => {
    const fileUpload = await uploadAWS(e);
    if (fileUpload.success) {
      setImage(fileUpload);
      field.onChange(fileUpload.location);
    }
  };
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, marginBottom: 3 , }}>
      <Box sx={{...sx}}>
        <Button sx={{
          color: fieldState.invalid && 'red',
          borderColor: fieldState.invalid && 'red',
        }} variant='outlined' component="label">
          {actionText ?? 'Upload Image'}
          <input hidden accept="image/*" type="file" onChange={handleClick} />
        </Button>
        {helperText && <FormLabel sx={{marginLeft: '10px'}}>{helperText}</FormLabel>}
        {fieldState.invalid && <FormHelperText sx={{color:'red'}}>The Image is required</FormHelperText>}
      </Box>
      {
        image && !noImage && (
          <img
            {...field}
            src={image?.location}
            alt={image?.key}
            loading="lazy"
            style={{ width: '60%', height: '60%', objectFit: 'contain', }}
          />
        )
      }
    </Box>
  );
};

export default InputButtonAws;
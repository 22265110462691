import { IconButton } from "@mui/material";
import { TopToolbar, DeleteButton } from "react-admin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const ShowToolbar = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1)
      };

    return (
        <TopToolbar sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <IconButton onClick={handleNavigate} ><ArrowBackIcon /></IconButton>
            <DeleteButton mutationMode="pessimistic"  sx={{}}/>
        </TopToolbar>
    );
};

export default ShowToolbar;

import { List, Datagrid, TextField, EmailField, DateField, FunctionField } from "react-admin";
import PostPagination from "../../components/PostPagination";

export const Bookinglist = () => (
  <List pagination={<PostPagination />}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="title" />
      <DateField options={{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }} source="time_selected" />
      <FunctionField label="Name"
        render={record => `${record.first_name} ${record.last_name}`} />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="num_participants" label="People" />
    </Datagrid>
  </List>
);

import { Box } from '@mui/material';
import { Menu } from 'react-admin';


export const MyMenu = () => (
    <Menu sx={{
        // backgroundColor: "#f7f7f7",
        width: "500px",
        height: "100%",
        "& .MuiButtonBase-root": {
            color: "#242424",

        },
        "& .RaMenuItemLink-active": {
            backgroundColor: "#ebebeb"
        }

    }}>
        <Menu.ResourceItem name="experiences" />
        <Menu.ResourceItem name="bookings" />
        <Menu.ResourceItem name="notifications" />
        <Menu.ResourceItem name="faqs" />
        <Menu.ResourceItem name="admins" />
    </Menu>
);